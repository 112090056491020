import ChargingPage from './ChargingPage'
import CalendarPage from '../../../../authenticated/pages/Calendar/Calendar'

export default function Calendar() {
  return (
    <ChargingPage redirectRoute="/connect-vehicle">
      <CalendarPage />
    </ChargingPage>
  )
}
