import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Fab as MuiFab,
  Grid as MuiGrid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { spacing } from '@material-ui/system'
import calendarStyle from './Calendar.style'
import { Add as AddIcon } from '@material-ui/icons'
import TripDialog, {
  KILOMETERS,
  MILES,
  PERCENT,
} from '../../components/dialogs/TripDialog'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import { isMobileDevice } from '../../../utils/sizeUtilities'
import { useDispatch, useSelector } from 'react-redux'
import { enableCalendar } from '../../../actions/calendar'
import { convertTime24to12, getDateNow } from '../../../utils/timeUtilities'
import Loader from '../../components/layout/Loader'
import { TripOnboarding } from '../Trips'
import { useHasCompletedProfile } from '../../hooks/useHasCompletedOnboarding'
import AlertMessage from '@/app/components/AlertMessage'
import { NavigationPage } from '@/app/components'
import { canCharge } from '@/utils/vehicles/permissions'
import { capitalizeFirstLetter } from '@/utils/stringUtilities'
import { tripsCollection, useTripsForVehicle } from '@/reducers/trips'
import { RequestStatus } from '@/request/types'
import { fetchAuthenticated } from '@/utils/fetch'
import VehiclePage from '@/app/components/VehiclePage/VehiclePage'

const Button = styled(MuiButton)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Fab = styled(MuiFab)(spacing)
const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`
const Card = styled(MuiCard)(spacing)
const CalendarCard = styled(Card)`
  border-radius: 20px;
`
const CardContent = styled(MuiCardContent)(spacing)

function Calendar({ vehicleId }) {
  const { trips, status } = useTripsForVehicle(vehicleId)
  const { createAction } = tripsCollection.useCreate()

  const [tripDialogOpen, setTripDialogOpen] = useState(false)
  const [trip, setTrip] = useState(null)
  const [clearTripsOpen, setClearTripsOpen] = useState(false)
  const [initialDate, setInitialDate] = useState(getDateNow())

  const setTripClose = () => setTripDialogOpen(false)
  const setClearTripsClose = () => setClearTripsOpen(false)
  const isMobile = isMobileDevice()

  const dispatch = useDispatch()

  const calendarComponentRef = React.createRef()

  const theme = useTheme()
  const isXSmallOrLess = useMediaQuery(theme.breakpoints.down('xs'))

  const calendarHeight = isXSmallOrLess ? '575px' : '540px'

  function addNewTrip(event, tripId = null) {
    event.preventDefault()
    let trip = {
      battery_target_max: event.target.max.value,
      battery_target_unit: event.target.unit.value,
      date: event.target.date.value,
      vehicle: vehicleId,
    }

    // Add seconds if needed
    let count = (event.target.time.value.match(/:/g) || []).length
    if (count === 2) {
      trip['time'] = event.target.time.value
    } else {
      trip['time'] = event.target.time.value + ':00'
    }

    if (tripId) {
      trip['id'] = tripId
      dispatch(tripsCollection.actions.update(tripId, trip))
    } else {
      dispatch(createAction(trip))
    }

    setTripClose()

    // let calendarApi = calendarComponentRef.current.getApi();
    // calendarApi.gotoDate(event.target.date.value);
  }

  function openAddTripFromCalendar(event) {
    setTrip(null)
    setInitialDate(event.dateStr)
    open()
  }

  function openAddTrip() {
    setTrip(null)
    setInitialDate(getDateNow())
    open()
  }

  function editTrip(info) {
    setTrip(info.event.extendedProps.trip)
    setTripDialogOpen(true)
  }

  function open() {
    setTrip(null)
    setTripDialogOpen(true)
  }

  function handleDelete(tripId) {
    dispatch(tripsCollection.actions.remove(tripId))
    setTripClose()
  }

  function handleDeleteTrips() {
    fetchAuthenticated('/trips/delete_all', { method: 'POST' }).then(
      (response) => {
        if (response.ok) {
          // clear state
          dispatch(tripsCollection.actions.invalidate())
        }
      },
    )
    setClearTripsClose()
  }

  function getCalendarEvents() {
    // let analytics = [];
    const events = trips.map((trip) => {
      let batteryUnit
      if (trip.battery_target_unit === PERCENT) {
        batteryUnit = '%'
      } else if (trip.battery_target_unit === MILES) {
        batteryUnit = ' mi'
      } else if (trip.battery_target_unit === KILOMETERS) {
        batteryUnit = ' km'
      } else {
        console.log(
          `Unknown battery_target_unit ${trip['battery_target_unit']}`,
        )
        batteryUnit = ''
      }
      const title = trip.time_local_timezone
        ? `${trip['battery_target_max']}${batteryUnit} ${convertTime24to12(
            trip['time_local_timezone'],
          )}`
        : `${trip['battery_target_max']}${batteryUnit} ${dayjs(
            trip.departure_datetime_utc,
          ).format('h:mm a')}`

      return {
        title: title,
        start: trip.departure_datetime_utc,
        allDay: true,
        trip: trip,
      }
    })

    return events
  }

  if (status != RequestStatus.Succeeded) {
    return <Loader />
  }

  return (
    <CalendarCard mb={6} style={{ boxShadow: '0 0 10px 0 rgba(53,64,82,.25)' }}>
      <CardContent p={6}>
        <FullCalendarWrapper>
          {/* increasing height on mobile */}
          <FullCalendar
            height={calendarHeight}
            eventClick={editTrip}
            dateClick={openAddTripFromCalendar}
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, interactionPlugin]}
            events={getCalendarEvents()}
            editable={true}
            titleFormat={{
              year: 'numeric',
              month: isMobile ? 'short' : 'long',
            }}
            header={{
              left: 'prev,next',
              center: 'title',
              right: 'today',
            }}
            ref={calendarComponentRef}
          />
        </FullCalendarWrapper>
        <Grid container justifyContent="space-between" mt={3}>
          <Grid item style={{ visibility: 'hidden' }}>
            <Fab size="medium" color="secondary" aria-label="Add">
              <AddIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              size="medium"
              color="primary"
              aria-label="Add"
              onClick={openAddTrip}
            >
              <AddIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              size="medium"
              onClick={() => setClearTripsOpen(true)}
            >
              Clear all
            </Button>
          </Grid>
        </Grid>
        <TripDialog
          date={initialDate}
          trip={trip}
          open={tripDialogOpen}
          onClose={() => setTripClose()}
          onSubmit={addNewTrip}
          handleDelete={handleDelete}
        />
        <ConfirmDialog
          textContent={
            'This action will remove all your scheduled trips. We will continue to charge your car as normal.'
          }
          open={clearTripsOpen}
          onClose={() => setClearTripsClose()}
          onSubmit={handleDeleteTrips}
        />
      </CardContent>
    </CalendarCard>
  )
}

function CalendarOrDependencyAlert({ vehicleId }) {
  // Get selected vehicle
  const canVehicleCharge = useSelector((state) =>
    canCharge(state.vehicles?.vehicles?.find((v) => v.id === vehicleId)),
  )

  const hasHome = useSelector((state) => Boolean(state.homes.selectedHome))

  if (!canVehicleCharge) {
    return (
      <AlertMessage variant="info">
        Your default vehicle does not support third-party charge management. If
        you would like to change your default vehicle, navigate to the{' '}
        <Link to="/settings">profile</Link> tab.
      </AlertMessage>
    )
  } else if (!hasHome) {
    return (
      <AlertMessage variant="info">
        <Link to="/connect-utility/address">Add your home</Link> to use the
        trips feature
      </AlertMessage>
    )
  }

  return <Calendar vehicleId={vehicleId} />
}

function CalendarPage(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector((state) => state.user.user)
  const isLoadingUser = useSelector((state) => state.user.isFetching)
  const [showLoader, setShowLoader] = useState(!currentUser || isLoadingUser)
  const hasCompletedProfile = useHasCompletedProfile()
  const hasEnabledCalendar = currentUser?.profile.has_enabled_calendar
  const { deviceId } = useParams()

  const vehicleSubtitle = useSelector((state) => {
    const vehicle = state.vehicles?.vehicles?.find(
      (v) => v.id === parseInt(deviceId),
    )
    if (!vehicle) {
      return undefined
    }
    const carName =
      vehicle?.car.display_name && vehicle?.car.display_name !== ''
        ? vehicle?.car.display_name
        : undefined
    const year = vehicle?.car.car_model?.year
    const modelName = vehicle?.car.car_model?.friendly_name
      ? vehicle?.car.car_model?.friendly_name
      : capitalizeFirstLetter(vehicle.car.car_model.model)
    return `${carName ? `${carName} •` : ''} ${year} ${modelName}`
  })

  useEffect(() => {
    setShowLoader(!currentUser || isLoadingUser)
  }, [currentUser, isLoadingUser])

  const getStarted = () => {
    // Enable calendar for user
    dispatch(enableCalendar())
    setShowLoader(true)
  }

  if (showLoader) {
    return <Loader />
  }

  return (
    <NavigationPage
      title="Trips"
      subtitle={vehicleSubtitle}
      navigationOptions={['back']}
    >
      <VehiclePage>
        <Grid container spacing={6} alignItems="center" justifyContent="center">
          {hasEnabledCalendar ? (
            <Grid item xs={12}>
              <CalendarOrDependencyAlert vehicleId={parseInt(deviceId)} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TripOnboarding
                getStartedAction={getStarted}
                getStartedLabel={hasCompletedProfile ? 'Enable' : 'Get Started'}
              />
            </Grid>
          )}
        </Grid>
      </VehiclePage>
    </NavigationPage>
  )
}

export default CalendarPage
