import styled from 'styled-components'
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'

const Dialog = styled(MuiDialog)(spacing)
const DialogContent = styled(MuiDialogContent)(spacing)

function ConfirmDialog(props) {
  function handleClose() {
    props.onClose()
  }

  function handleSubmit(event) {
    props.onSubmit(event)
    props.onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.textContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
