import { ConfigurableFunctionalityProvider } from '@/app/context/ConfigurableFunctionalityContext'
import { Redirect } from 'react-router-dom'
import PageLoader from '../../../../authenticated/components/layout/PageLoader'
import { useAppSelector } from '../../../../hooks'
import { selectedVehicle } from '../../../../selectors/vehicles'

function useViewModel() {
  const hasSelectedVehicle = useAppSelector((state) =>
    Boolean(selectedVehicle(state.vehicles.vehicles)),
  )
  const loading = useAppSelector((state) => !state.vehicles.hasFetched)
  return { hasSelectedVehicle, loading }
}

interface InternalProps {
  hasSelectedVehicle: boolean
  loading: boolean
}

interface ExternalProps {
  redirectRoute: string
  children: React.ReactNode
}

function ChargingPage(props: InternalProps & ExternalProps) {
  if (props.loading) {
    return <PageLoader />
  } else if (!props.hasSelectedVehicle) {
    return <Redirect to={props.redirectRoute} />
  }
  return <>{props.children}</>
}

export default function (props: ExternalProps) {
  const viewModel = useViewModel()
  return (
    <ConfigurableFunctionalityProvider scope="charging">
      <ChargingPage {...viewModel} {...props} />
    </ConfigurableFunctionalityProvider>
  )
}
