import { useSelector } from 'react-redux'
import { selectedVehicle as getSelectedVehicle } from '../../selectors/vehicles'

const hasSelectedObject = (value) => !!value

export function hasCompletedOnboarding(
  hasSelectedVehicle,
  hasSelectedHome,
  hasSelectedUtility,
  hasSelectedPlan,
  hasSelectedChargeSchedule,
) {
  return (
    hasSelectedVehicle &&
    hasSelectedHome &&
    hasSelectedUtility &&
    hasSelectedPlan &&
    hasSelectedChargeSchedule
  )
}

export function useHasCompletedProfile() {
  const selectedVehicle = useSelector((state) =>
    hasSelectedObject(getSelectedVehicle(state.vehicles.vehicles)),
  )
  const selectedHome = useSelector((state) =>
    hasSelectedObject(state.homes.selectedHome),
  )
  const selectedUtility = useSelector((state) =>
    hasSelectedObject(state.utilities.selectedUtility),
  )
  const selectedPlan = useSelector((state) =>
    hasSelectedObject(state.plans.selectedPlan),
  )
  const currentUserHasPendingPlan = useSelector(
    (state) => state.user.user?.profile.is_plan_pending,
  )
  const planForCheck = selectedPlan ? selectedPlan : currentUserHasPendingPlan
  return hasCompletedOnboarding(
    selectedVehicle,
    selectedHome,
    selectedUtility,
    planForCheck,
    {},
  )
}

/**
 * @deprecated - onboarding is now divied out via a survey and computed on the backend
 */
export function useHasCompletedOnboarding() {
  const hasCompletedProfile = useHasCompletedProfile()
  const currentUserHasMadeChargeScheduleSelection = useSelector(
    (state) => state.user.user?.profile?.has_made_charge_schedule_selection,
  )
  return currentUserHasMadeChargeScheduleSelection && hasCompletedProfile
}
