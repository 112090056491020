import OnboardingCard from '../../components/Onboarding/OnboardingCard'

const TripOnboarding = (props) => (
  <OnboardingCard
    image="/dashboard/img/charging/calendar.webp"
    imageText="Schedule Trip"
    heading="Schedule an upcoming trip"
    content="Specify a battery target for your trip so you can leave with the range you need."
    getStartedLabel={props.getStartedLabel}
    getStartedAction={props.getStartedAction}
  />
)

export default TripOnboarding
