import styled from 'styled-components'

import {
  Box as MuiBox,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Typography as MuiTypography,
  makeStyles,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'

const Card = styled(MuiCard)(spacing)
const OuterCard = styled(Card)`
  border-radius: 20px;
`

const CardContent = styled(MuiCardContent)(spacing)
const Typography = styled(MuiTypography)(spacing)

const Button = styled(MuiButton)(spacing)
const Box = styled(MuiBox)(spacing)

const Centered = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CardMediaSpacing = styled(MuiCardMedia)(spacing)
const CardMedia = styled(CardMediaSpacing)`
  height: 180px;
  width: 180px;
`

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '20px',
    width: '170px',
    fontWeight: '700',
  },
}))

export default function OnboardingCard(props) {
  const classes = useStyles()

  return (
    <OuterCard elevation={0} p={0}>
      {props.image && (
        <Centered>
          <CardMedia image={props.image} title={props.imageTitle} />
        </Centered>
      )}
      <CardContent p={0}>
        {props.heading && (
          <Centered>
            <Typography variant="h5" component="div" gutterBottom mb={3}>
              {props.heading}
            </Typography>
          </Centered>
        )}
        {props.content && (
          <Centered>
            <Typography
              style={{ width: '75%' }}
              variant="body1"
              component="div"
              gutterBottom
            >
              {props.content}
            </Typography>
          </Centered>
        )}
        {props.children}
      </CardContent>
      {props.getStartedAction && (
        <Centered>
          <Button
            className={classes.button}
            mb={9}
            variant="contained"
            color="primary"
            onClick={() => props.getStartedAction()}
          >
            {props.getStartedLabel || 'Get Started'}
          </Button>
        </Centered>
      )}
      {props.skipAction && (
        <Centered>
          <Button mb={3} color="primary" onClick={() => props.skipAction()}>
            Skip for Later
          </Button>
        </Centered>
      )}
      {props.bottomText && (
        <Centered>
          <Typography
            style={{ width: '75%' }}
            variant="body1"
            component="div"
            gutterBottom
          >
            {props.bottomText}
          </Typography>
        </Centered>
      )}
    </OuterCard>
  )
}
